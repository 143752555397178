import https from "./https"
import store from "@/store"

const organization = store.state.general.organization

const richMenu = {
  getRichMenus(params) {
    return https.get(`admin/organizations/${organization.id}/line-richmenus`, { params })
  },

  createRichMenu(params) {
    return https.post(`admin/organizations/${organization.id}/line-richmenus`, params)
  },

  getRichMenu(id) {
    return https.get(`admin/organizations/${organization.id}/line-richmenus/${id}`)
  },

  updateRichMenu(id, params) {
    return https.put(`admin/organizations/${organization.id}/line-richmenus/${id}`, params)
  },
  removeRichMenu(id) {
    return https.delete(`admin/organizations/${organization.id}/line-richmenus/${id}`)
  },
  uploadImage(file) {
    return https.post(`general/images/upload`, file)
  },
  setSchedule(id) {
    return https.post(`admin/organizations/${organization.id}/line-richmenus/${id}/set-schedule`)
  },
  unpublish(id) {
    return https.post(`admin/organizations/${organization.id}/line-richmenus/${id}/unpublish`)
  },
  publish(id) {
    return https.post(`admin/organizations/${organization.id}/line-richmenus/${id}/publish`)
  },
  setDefault(id) {
    return https.post(`admin/organizations/${organization.id}/line-richmenus/${id}/set-default`)
  },
  getCustomers(id, params) {
    return https.get(`admin/organizations/${organization.id}/line-richmenus/${id}/customers`, { params })
  },
  linkCustomer(id, customerId) {
    return https.post(`admin/organizations/${organization.id}/line-richmenus/${id}/link-user`, {
      customer_id: customerId,
    })
  },
  unlinkCustomer(customerId) {
    return https.post(`admin/organizations/${organization.id}/line-richmenus/unlink-user`, {
      customer_id: customerId,
    })
  },
  syncFromLine() {
    return https.post(`admin/organizations/${organization.id}/line-richmenus/sync-from-line`)
  },
  batchSetSchedule(params) {
    return https.post(`admin/organizations/${organization.id}/line-richmenus/batch-set-schedule`, params)
  },
  batchPublish(params) {
    return https.post(`admin/organizations/${organization.id}/line-richmenus/batch-publish`, params)
  },
  batchDelete(params) {
    return https.delete(`admin/organizations/${organization.id}/line-richmenus/batch-delete`, { params })
  }
}

export default richMenu
