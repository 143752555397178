<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{ name: 'DashboardHome' }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item>LINE 主控台</b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'RichMenu' }">圖文選單</b-breadcrumb-item>
            <b-breadcrumb-item active>{{ richMenu.title }}</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-overlay :show="isFetching">
      <b-card>
        <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">{{ `已綁定${richMenu.title}的會員` }}</h4>

        <div class="d-flex justify-content-end">
          <div>
            <StaffAddCustomer
              ref="staffAddCustomer"
              add-button-text="綁定會員"
              @bind="linkCustomer"
            ></StaffAddCustomer>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <b-table
              striped
              hover
              responsive
              :items="customers"
              :fields="fields"
            >
              <template #cell(avatar_url)="data">
                <div class="d-flex align-items-center justify-content-center">
                  <router-link :to="{ name: 'CustomerDetailView', params: { customerID: data.item.id } }" >
                    <b-avatar
                      :src="data.item.avatar_url"
                      variant="secondary"
                      size="2rem"
                    ></b-avatar>
                  </router-link>
                </div>
              </template>
              <template #cell(name)="data">
                <router-link :to="{ name: 'CustomerDetailView', params: { customerID: data.item.id } }" >{{ data.item.name | hiddenString(20) }}</router-link>
              </template>
              <template #cell(action)="data">
                <div class="d-flex justify-content-center">
                  <b-button variant="inverse-danger" size="sm" @click="unlinkCustomer(data.item)">
                    解除綁定
                  </b-button>
                </div>
              </template>
            </b-table>
          </div>
        </div>

        <div class="d-flex justify-content-center" style="margin-top: 80px">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            @change="fetchCustomers"
          ></b-pagination>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { BAvatar } from "bootstrap-vue";
import richMenuApi from "@/apis/richmenu";
import moment from "moment";
import StaffAddCustomer from "@/pages/Dashboard/Staff/StaffAddCustomer.vue";

import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
  components: { BAvatar, StaffAddCustomer },

  data() {
    return {
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      isFetching: false,
      richMenu: {
        title: "",
      },
      customers: [],
      fields: [
        { key: "avatar_url", label: "" },
        { key: "name", label: "LINE 名稱" },
        { key: "outer_code", label: "平台ID" },
        { key: "mobile_phone", label: "手機" },
        { key: "email", label: "Email" },
        {
          key: "birthday",
          label: "生日",
          formatter: (value) => {
            return value ? moment(value).format('YYYY-MM-DD') : '';
          }
        },
        { key: 'action', label: '操作' },
    ],
    };
  },

  mounted() {
    this.fetchRichMenu()
    this.fetchCustomers()
  },

  methods: {
    async fetchRichMenu() {
      const { data } = await richMenuApi.getRichMenu(
        this.$route.params.menuID
      );
      this.richMenu = data.data
    },
    async fetchCustomers() {
      try {
        this.isFetching = true;
        const { data } = await richMenuApi.getCustomers(this.$route.params.menuID, {
          per_page: this.perPage,
          page: this.currentPage,
        });
        this.customers = data.data
        this.totalRows = data.meta.total
      } catch (e) {
        console.log(e)
      }
      this.isFetching = false
    },
    async linkCustomer(customer) {
      this.$swal({
        title: "確定要綁定嗎?",
        html: `
          <div class="d-block">
            <div class="my-3 text-left">
              <div>消費者姓名:${customer.name}</div>
            </div>
          </div>`,
        type: "warning",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: "返回",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "確認綁定",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {
          try {
            await this.doLinkCustomer(customer)
          } catch (e) {
            console.log(e)
          } finally {
            this.$refs.staffAddCustomer.refreshSearch()
            this.$refs.staffAddCustomer.closeModal()
          }
        }
      })
    },
    async doLinkCustomer(customer) {
      this.isFetching = true
      try {
        await richMenuApi.linkCustomer(this.$route.params.menuID, customer.id)
        await this.fetchCustomers()
      } catch (error) {
        console.error(error);

        this.$swal(
          "綁定失敗",
          `${customer.name} 綁定失敗`,
          "warning"
        );
      }
      this.isFetching = false
    },
    async unlinkCustomer(customer) {
      const result = await this.$swal({
        title: "解除綁定",
        text: `確定要解除 ${customer.name} 的綁定嗎?`,
        type: "warning",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: "取消",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "確認",
        reverseButtons: true,
      })
      if (! result.value) {
        return
      }

      this.isFetching = true
      try {
        await richMenuApi.unlinkCustomer(customer.id)
        await this.fetchCustomers()
      } catch (error) {
        console.error(error);

        this.$swal(
          "解除失敗",
          `${customer.name} 解除失敗`,
          "warning"
        );
      }
      this.isFetching = false
    },
  },
};
</script>

<style></style>
